// @flow
import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import type { FrontMatter } from '../utils/types';
import getDateString from '../utils/date';

const Container = styled(GatsbyLink)`
  display: flex;
  flex-direction: column;
  color: unset;
  transition: background-color 0.3s ease-in;

  &:hover,
  &:active {
    color: unset;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Title = styled.h3`
  padding: 0 24px;
  margin-top: 1.66667rem;
  margin-bottom: 0.833335rem;
`;

export const PagePreviewCardDivider = styled.hr`
  margin-bottom: 0;
`;

const Date = styled.p`
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0.833335rem;
  padding: 0 24px;
`;

const Preview = styled.p`
  padding: 0 24px;
`;

export class PagePreviewCard extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Container to={frontMatter.route}>
        <Title>{frontMatter.title}</Title>
        {frontMatter.date && <Date>{getDateString(frontMatter.date)}</Date>}
        <Preview>{frontMatter.preview}</Preview>
        <PagePreviewCardDivider />
      </Container>
    );
  }
}
